import { createStore } from 'vuex'

uuid = ()=>([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,c=()->(c^crypto.getRandomValues(new Uint8Array(1))[0]&15 >> c/4).toString(16))

Cookies = require('@/lib/Cookie.js')

defSess =
    date: Date.now()
    refer: document.referrer
defUser =
     _id       : ''
     type      : "session"
     dateCreate: Date.now()
     sessions  : []
     data      : {}
     yaId      : ""
     goId      : ""

defUser.sessions.push defSess



if !Cookies.get('uuid')
    Cookies.set('uuid',uuid(),{ expires: 365 })
else
    defUser._id = Cookies.get('uuid')




store = createStore
    state:
        conf : require('@/etc/confSite.coffee')
        blogs: []
        produckts: {}
        filters: []
        catalog: []
        basket: []
        basketC: []
        cookies: Cookies
        artikul: ''
        docIdOld:''
        post: {}
        path: ''
        doc: {}
    mutations:
        save: (state)->
           Cookies.set('basket',store.state.basket,{ expires: 365 })
           Cookies.set('basketC',store.state.basketC,{ expires: 365 })
        goDoc: (state)->
            if state.artikul != '' and state.produckts[state.artikul]
               if state.docIdOld != state.produckts[state.artikul].id
                  state.docIdOld = state.produckts[state.artikul].id
                  path = 'https://' + state.conf.host + ':' + state.conf.port + '/' + state.conf.base + '/' + state.produckts[state.artikul].id
                  fetch path
                     .then (res)->
                        res.json()
                     .then (json)->
                        state.doc = json
    getters:
        catalogF: (state)->
           tmp = {}
           for k,v of state.catalog
              for x,y of v
                 for i in y
                    if i in state.filters
                       if !tmp[k]
                           tmp[k] = []
                       tmp[k].push x
           return tmp
        producktLists: (state)->
           if state.filters.length > 0
               obj = {}
               for item in state.filters
                   item = item.split ':'
                   if state.catalog[item[0]]
                       if !obj[item[0]]
                          obj[item[0]] = []
                          obj[item[0]] = [...new Set( state.catalog[item[0]][item[1]] )]
                       else
                          obj[item[0]] = [...obj[item[0]], ...new Set( state.catalog[item[0]][item[1]] )]
                          obj[item[0]] = [...new Set( obj[item[0]] )]
                   else
                      return []
               tmp=[]
               i = true
               for v,k of obj
                   if i
                       tmp = k
                       i = false
                   else
                       tmp =  [...new Set(tmp)].filter (item )->
                                                               k.includes(item)

                       #)  #[...new Set(k)].filter((item)->{ tmp.includes(tmp) })
               return tmp
           else
               tmp = []
               for i in [1..46]
                   tmp.push Object.keys(state.produckts)[(Math.floor(Math.random() * (Object.keys(state.produckts).length-1)))]
               return tmp
        producktListsCount: (getters)->
            if getters.producktLists
                return 0
            else
                return getters.producktLists.length
    modules:
        session: createStore
            state: defUser
            mutations: {}
            actions: {}
            modules: {}


if Cookies.get('basket')
    store.state.basket = Cookies.get('basket')
if Cookies.get('basketC')
    store.state.basketC = Cookies.get('basketC')

#fetch 'https://' + store.state.conf.host + ':' + store.state.conf.port + '/' + store.state.conf.base + '/_design/site/_view/blogTeg?reduce=false&limit=20'
fetch '/blogTeg.json'
    .then (res)->
        res.json()
    .then (json)->
        store.state.blogs = json.rows
        console.dir json.rows

#fetch 'https://' + store.state.conf.host + ':' + store.state.conf.port + '/' + store.state.conf.base + '/_design/site/_view/options3?reduce=true'
fetch '/options4.json'
    .then (res)->
         res.json()
    .then (json)->
         store.state.catalog = json.rows[0]["value"]


#fetch 'https://' + store.state.conf.host + ':' + store.state.conf.port + '/' + store.state.conf.base +  '/_design/site/_view/artikul?reduce=true'
fetch '/artikul.json'
    .then (res)->
         res.json()
    .then (json)->
         store.state.produckts = json.rows[0]["value"]

export default store


export default
    data    : ()->
         return
           name: ''
           phone:  ''
           msg: ''
    components:
        'vs-crumbs': require('../components/breadcrumbs.vue').default
    methods:
        send: ()->
           @.name = ''
           @.phone = ''
           @.msg = 'Спасибо, наш сотрудник свяжется с Вами в ближайшее время.'
           @.$store.state.basket = []
           @.$store.state.basketC = []
           @.$store.commit('save')



export default
     mounted: ()->
          console.dir @
          obj = @
          interval = setInterval( obj.left, 500)
     data   : ()->
          return
               sliders: ''
               interval: ''
     components: {}
     computed:
          conf: ()->
               return @.$store.state.conf
          produckts: ()->
               return @.$store.state.produckts
          actions: ()->
               tmp = []
               for i in @.$store.state.blogs
                    if i.key[0][0] == "Акция"
                         tmp.push i
               if tmp[0]
                    @.sliders = tmp[0].id
               tmp = [
                      {id: 'Комбикорм от производителя для КРС.',desc: 'Подробности по телефону:', phone: '+7 926 968-47-31', img: 'sliderb000'}
                      {id: 'Комбикорм от производителя для пушных зверей.',desc: 'Подробности по телефону:', phone: '+7 926 968-47-31', img: 'sliderk000'}
                      {id: 'Комбикорм от производителя для лошадей.',desc: 'Подробности по телефону:', phone: '+7 926 968-47-31', img: 'sliderl000'}
                      {id: 'Комбикорм от производителя для птиц.',desc: 'Подробности по телефону:', phone: '+7 926 968-47-31', img: 'sliderp000'}
                      {id: 'Комбикорм от производителя для рыб.',desc: 'Подробности по телефону:', phone: '+7 926 968-47-31', img: 'sliderr000'}

                      {id: 'Комбикорм от производителя для КРС.',desc: 'Подробности по телефону:', phone: '+7 926 968-47-31', img: 'sliderb001'}
                      {id: 'Комбикорм от производителя для пушных зверей.',desc: 'Подробности по телефону:', phone: '+7 926 968-47-31', img: 'sliderk001'}
                      {id: 'Комбикорм от производителя для лошадей.',desc: 'Подробности по телефону:', phone: '+7 926 968-47-31', img: 'sliderl000'}
                      {id: 'Комбикорм от производителя для птиц.',desc: 'Подробности по телефону:', phone: '+7 926 968-47-31', img: 'sliderp001'}
                      {id: 'Комбикорм от производителя для рыб.',desc: 'Подробности по телефону:', phone: '+7 926 968-47-31', img: 'sliderr001'}

                      {id: 'Комбикорм от производителя для КРС.',desc: 'Подробности по телефону:', phone: '+7 926 968-47-31', img: 'sliderb002'}
                      {id: 'Комбикорм от производителя для пушных зверей.',desc: 'Подробности по телефону:', phone: '+7 926 968-47-31', img: 'sliderk002'}
                      {id: 'Комбикорм от производителя для лошадей.',desc: 'Подробности по телефону:', phone: '+7 926 968-47-31', img: 'sliderl002'}
                      {id: 'Комбикорм от производителя для птиц.',desc: 'Подробности по телефону:', phone: '+7 926 968-47-31', img: 'sliderp002'}

                      {id: 'Комбикорм от производителя для КРС.',desc: 'Подробности по телефону:', phone: '+7 926 968-47-31', img: 'sliderb003'}
                      {id: 'Комбикорм от производителя для пушных зверей.',desc: 'Подробности по телефону:', phone: '+7 926 968-47-31', img: 'sliderk003'}
                      {id: 'Комбикорм от производителя для птиц.',desc: 'Подробности по телефону:', phone: '+7 926 968-47-31', img: 'sliderp003'}
                     ]
               @.sliders = tmp[0].img
               return tmp
     methods:
        sliderRight: ()->
            for i, it of @.actions
               if it.img == @.sliders
                  break
            if i  <  (@.actions.length - 1)
               @.sliders = @.actions[i*1+1].img
            else
               @.sliders = @.actions[ 0].img
            return
        sliderLeft: ()->
            for i, it of @.actions
               if it.img == @.sliders
                  break
            if i > 0
               @.sliders = @.actions[i*1-1].img
            else
               @.sliders = @.actions[ @.actions.length-1].img
            return


export default
    data   : ()->
         return
             price: [
                {
                   nameK: "КОМБИКОРМА ДЛЯ  ПТИЦ"
                   class: "pictoP"
                   list: [
                        {
                            art: "ПК-0"
                            name: "от 0 до 4 дней (пестартерный). Для кур – несушек."
                            price: 33230
                            show: false
                        }
                        {
                            art: "ПК-1-1"
                            name: "48 недель и более . Для кур – несушек."
                            price: 32120
                            show: false
                        }
                        {
                            art: "ПК-1-2"
                            name: "48 недель и более . Для кур – несушек."
                            price: 26000
                            show: true
                        }
                        {
                            art: "ПК-2"
                            name: "1-7 недель. Для молодника кур."
                            price: 33200
                            show: true
                        }
                        {
                            art: "ПК-3"
                            name: "8-13, 18-20 недель. Для кур."
                            price: 24800
                            show: false
                        }
                        {
                            art: "ПК-4"
                            name: "14-17 недель. Для кур."
                            price: 24800
                            show: false
                        }
                        {
                            art: "ПК-5"
                            name: "1-4 недели (стартерный). Для бройлеров."
                            price: 46200
                            show: true
                        }
                        {
                            art: "ПК-6"
                            name: "5 недель и старше. Для бройлеров."
                            price: 36800
                            show: true
                        }
                        {
                            art: "ПК-50"
                            name: "Перепела 1-4 недели"
                            price: 36350
                            show: false
                        }
                        {
                            art: "ДК-51"
                            name: "Перепела 5-6 недель"
                            price: 33100
                            show: false
                        }
                        {
                            art: "ДК-52"
                            name: "Перепела 7 недель и старше"
                            price: 31539
                            show: true
                        }
                        {
                            art: ""
                            name: "Комбикорм универсальный"
                            price: 19000
                            show: false
                        }
                        {
                            art: "ПК-11"
                            name: "Индейки"
                            price: 44500
                            show: true
                        }
                        {
                            art: "ДК-62"
                            name: "Страусы 37-63 недель."
                            price: 33533
                            show: true
                        }
                   ]
                }
                {
                   nameK: "КОМБИКОРМА ДЛЯ КРС"
                   class: "pictoB"
                   list: [
                        {
                            art: "КК-60-1"
                            name: "Для дойных коров в стойловый период"
                            price: 21800
                            show: false
                        }
                        {
                            art: "КК-60-2"
                            name: "Для высокоудойных коров в стойловый период"
                            price: 25000
                            show: true
                        }
                        {
                            art: "КК-62"
                            name: "Для телят в возрасте от 1 до 6 месяцев"
                            price: 32800
                            show: true
                        }
                        {
                            art: "КК-63-1"
                            name: "Для молодняка КРС от 6 до 12 месяцев"
                            price: 25500
                            show: true
                        }
                        {
                            art: "КК-64-1"
                            name: "Для молодняка КРС от 12 до 18 месяцев"
                            price: 21800
                            show: false
                        }
                   ]
                }
                {
                   nameK: "КОМБИКОРМА ДЛЯ ПУШНЫХ ЗВЕРЕЙ"
                   class: "pictoK"
                   list: [
                       {
                            art: "ПЗК-90"
                            name: "Для молодняка кролика"
                            price: 27000
                            show: true
                        }
                        {
                            art: "ПЗК-91"
                            name: "Для взрослого кролика"
                            price: 24500
                            show: true
                        }
                   ]
                }
                {
                   nameK: "КОМБИКОРМА ДЛЯ РЫБ"
                   class: "pictoR"
                   list: [
                        {
                            art: "КРК-111"
                            name: "Для рыб производителей (карпа)"
                            price: 28000
                            show: true
                        }
                        {
                            art: "КРК-110"
                            name: "Для сеголеток карпа"
                            price: 26100
                            show: false
                        }
                   ]
                }
                {
                   nameK: "КОМБИКОРМА ДЛЯ ЛОШАДЕЙ"
                   class: "pictoL"
                   list: [
                        {
                            art: "ЛК-72-1"
                            name: "Для тренируемых и спортивных лошадей"
                            price: 26300
                            show: true
                        }
                   ]
                }
            ]
    methods:
        probel: (a)->
            return (a+"").replace(/(\d{1,3})(?=((\d{3})*)$)/g, "&nbsp;$1")


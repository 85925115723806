
export default {
    created: ()->
       #@.map = new google.maps.Map document.getElementById(@.name), @.mapP

    mounted: ()->
       obj             = @
       script          = document.createElement('script')
       script.src      = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDxieLoTT5SWSuosyEyuq1IRxSvnso738k&callback=initMap'
       script.async    = true
       window.initMap  = ()->
               obj.map = new google.maps.Map document.getElementById(obj.name), obj.mapP

       document.head.appendChild(script)

    data   : ()->
        map : {}
        mapP:
              center   : {lat: -34.397, lng: 150.644}
              zoom     : 8
              key      : 'AIzaSyDxieLoTT5SWSuosyEyuq1IRxSvnso738k'
              callback : 'initMap'
              libraries: 'places'
    props  : [
               'name'
             ]
}

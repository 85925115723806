
export default
     props:
         root: String
     computed:
         crumbs: ()->
            path = ''
            title = (this.root || 'Главная')
            cs =  [ { name: title, path: '/'} ]
            if !this.$route
                 return []
            r = (decodeURI(this.$route.path)                        ).split('/')
            if this.$route.matched.length > 0
              m = (this.$route.matched[0].meta.crumbs || '').split('/')
              for i in [1..(r.length - 1)]
                name = m[i] || r[i]
                if r[i] == ''
                    continue
                title += ' : '+ decodeURI(name)
                path  += '/'  + decodeURI(name)
                cs.push { name: name, path: path }
            @.$store.state.path = path
            window.document.title = 'ОАО «Михневохлебопродукт» :' + title
            window.scrollTo(0,0)
            return cs

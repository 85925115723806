
export default
    name      : 'content-block'
    components:
        'vs-crumbs': require('../components/breadcrumbs.vue').default
    computed:
        count: ()->
           return @.$store.state.count
    mounted: ()->
        return 0
    methods:
        test: (a)->
            console.dir a

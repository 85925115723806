import { createRouter, createWebHistory } from 'vue-router'

export default createRouter
   history: createWebHistory(process.env.BASE_URL)
   scrollBehavior: (to, from, savedPosition)->
        console.dir to
        console.dir from
        console.dir savedPosition
        return { x: 0, y: 0 }
   routes: [
         {
            path: encodeURI('/О нас')
            name: 'О нас'
            component: require('../views/about.vue').default
            meta:
                crumbs: 'О нас'
            children:[]
         }
         {
            path: encodeURI('/Технологии')
            name: 'Технологии'
            component: require('../views/techno.vue').default
            meta:
                crumbs: 'Технологии'
            children:[]
         }
         {
            path: encodeURI('/Инфраструктура')
            name: 'Инфраструктура'
            component: require('../views/infra.vue').default
            meta:
                crumbs: 'Инфраструктура'
            children:[]
         }
         {
            path: encodeURI('/Документы')
            name: 'Документы'
            component: require('../views/docs.vue').default
            meta:
                crumbs: 'Документы'
            children:[]
         }
         {
            path: encodeURI('/Контакты')
            name: 'Контакты'
            component: require('../views/contact.vue').default
            meta:
                crumbs: 'Контакты'
            children:[]
         }
         {
            path: encodeURI('/Продукция')
            name: 'Продукция'
            component: require('../views/produckt.vue').default
            meta:
                crumbs: 'Продукция'
            children:[]
         }
         {
            path: encodeURI('/Заказать звонок')
            name: 'Заказать звонок'
            component: require('../views/messge.vue').default
            meta:
                crumbs: 'Заказать звонок'
            children:[]
         }
         {
            path: '/'
            alias: [encodeURI('/Главная/'),'']
            name: 'Главная'
            component: require('../views/home.vue').default
            meta:
                crumbs: 'Главная'
            children:[]
         }

   ]

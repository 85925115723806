
export default
    name      :          'app'
    data      : ()->
        return
             menuShow: false
    components:
        'header-block' : require('./blocks/header.vue' ).default
        'content-block': require('./blocks/content.vue').default
        'footer-block' : require('./blocks/footer.vue' ).default
    computed:
        count: ()->
           return @.$store.state.count
    mounted: ()->
        #================ metrica ============================
        ((m, e, t, r, i, k, a) ->
          m[i] = m[i] or ->
            (m[i].a = m[i].a or []).push arguments
            return
          m[i].l = 1 * new Date
          k = e.createElement(t)
          a = e.getElementsByTagName(t)[0]
          k.async = 1
          k.src = r
          a.parentNode.insertBefore(k, a)
          return
        ) window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym'
        ym 87322434, 'init',
          clickmap: true
          trackLinks: true
          accurateTrackBounce: true
          webvisor: true
        #========================================================
        #================= analitics ============================
        script         = document.createElement('script')
        script.src     = 'https://www.googletagmanager.com/gtag/js?id=G-BY5BK58WM2'
        script.async   = true
        document.head.appendChild(script)

        window.dataLayer = window.dataLayer || []
        gtag = ()->
            dataLayer.push(arguments)
        gtag('js'    , new Date())
        gtag('config', 'G-BY5BK58WM2')
        #=========================================================
        return 0
    methods:
        test: (a)->
            console.dir a
    watch:
        $route: (to, from)->
            @.$root.menuShow = false
            if @.$route.hash == ''
                window.scroll(0, 0)


export default
    name      : 'footer-block'
    components: {}
    computed:
        count: ()->
           return @.$store.state.count
    mounted: ()->
        return 0
    methods:
        test: (a)->
            console.dir a


export default
    mounted: ()->
        console.dir @
        script         = document.createElement('script')
        script.src     = 'https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3A3f33f6e12be160a2bc7eb416d954c2bc270dbb0d4b98b4817b66a6f2906b84ce&amp;width=100%25&amp;height=498&amp;lang=ru_RU&amp;&id=map'
        script.async   = true
        document.head.appendChild(script)
    data   : ()->
         return {}
    computed:
        conf: ()->
            return @.$store.state.conf
    components:
        'vs-crumbs'      : require('../components/breadcrumbs.vue').default
        'slider-block'   : require('../components/slider.vue').default
        'gmap-block'     : require('../components/gmaps.vue' ).default

import { createApp }  from 'vue'
//import vueBreadcrumbs from 'vue-breadcrumbs'
import app            from '@/App.vue'
import router         from '@/router/index.coffee'
import store          from '@/store'
import breadcrumbs    from '@/components/breadcrumbs.vue'


window.app = createApp(app)
.use(router)
.use(store)
// .use(vueBreadcrumbs, breadcrumbs)
.mount('#app')
